import axios from '../../public/static/js/axios.min';
import common from "../common/js/common"; //公共封装js
import installDrag from "../common/js/drag"; //拖拽
window.axios = axios;
window.common = common;
window.axios.jsonp = common.axiosJsonp; //拓展axios

const components = []
const install = function(Vue, opts = {}) {
    if (install.installed) return;
    install.installed = true;
    components.forEach(component => {
        Vue.component(component.name, component);
    });
    installDrag(Vue);
}
if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue)
}

export default {
    install,
}
