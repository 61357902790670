export default {
  title: "ระบบให้บริการสืบค้นอาคารชุด ห้องชุด",
  header: {
    feedback: 'แบบสำรวจความพึงพอใจ',
    news: 'ข่าวประกาศ',
    submit: 'ส่งมอบ',
    cancel: 'ยกเลิก',
    userNum: 'จำนวนผู้ใช้งาน:',
    home: 'เข้าสู่ระบบ',
    logout: 'ออกจากระบบ',
  },
  leftContainer: {
    condominium: 'อาคารชุด',
    apartment: 'ห้องชุด',
    province: 'จังหวัด',
    category: 'หมวดหมู่',
    search: 'ค้นหา',
    reset:'ตั้งใหม่',
    input: 'ป้อนข้อความค้นหา',
    select: 'เลือกหมวดหมู่',
    licInput: 'เลขที่',
    regInput: 'ปี พ.ศ.',
    loading: 'กำลังโหลด',
    options: {
      titleDeedNumber: 'เลขที่โฉนดที่ดิน',
      condominiumRegistrationNumber: 'ทะเบียนอาคารชุดเลขที่',
      condominiumName: 'ชื่ออาคารชุด',
      apartmentRegistrationNumber: 'ทะเบียนอาคารชุดเลขที่',
      apartmentName: 'ชื่ออาคารชุด',
      apartmentRoomNumber: 'เลขที่ห้องชุด'
    },
    table: {
      province: 'จังหวัด',
      district: 'อำเภอ/เขต',
      titleDeedNum: 'เลขที่โฉนดที่ดิน',
      condoRegisNum: 'ทะเบียนอาคารชุดเลขที่',
      condomNameTh: 'ชื่ออาคารชุด (ภาษาไทย)',
      condomNameEn: 'ชื่ออาคารชุด (ภาษาอังกฤษ)',
      officeName: 'ชื่อสำนักงานที่ดิน',
      roomNum:'เลขที่ห้องชุด',
      thaiYear: 'ปี พ.ศ. ที่จดทะเบียน',
      model: 'อาคารชุด 3 มิติ',
    }
  },
  rightContainer: {
    layerManagment: 'การจัดการชั้นข้อมูล',
    measurement: 'การวัดระยะ',
    distance: 'ระยะทาง',
    area: 'พื้นที่',
    height: 'ความสูง',
    apartmentArea: 'การใช้ประโยชน์พื้นที่',
    clear: 'ล้าง',
    layer: 'ชั้นข้อมูล',
    baseMap: 'แผนที่ฐาน'
  },
  popContainer:{
    condominiumInfo: 'ข้อมูลอาคารชุด',
    apartmentInfo: 'ข้อมูลห้องชุด',
  },
  indoorBox: {
    indoor: 'แสดงผลอาคารชุดทุกชั้น',
    offset: 'แยกแสดงแผนผังชั้น',
    displayUpFloors: 'แสดงชั้นที่',
    yes: 'ใช่',
    no: 'ไม่'
  },
  popContainerFixed:{
    condominiumName: 'ชื่ออาคารชุด',
    projectLocation: 'ที่ตั้งโครงการ',
    buildingNo: 'จำนวนอาคาร',
    floorsNo: 'จำนวนชั้น',
    roomsNo: 'จำนวนห้อง',
    condominiumJuristicPerson: 'ชื่อนิติบุคคลอาคารชุด',
    condominiumJuristicPersonOfficeLocation: 'ที่ตั้งสำนักงานนิติบุคคลอาคารชุด',
    condominiumJuristicManagerName: 'ชื่อผู้จัดการนิติบุคคลอาคารชุด',
    condominiumLocation: 'ตำแหน่งอาคารชุด',
    titleDeedNo: 'เลขที่โฉนดที่ดิน',
    parcelSurveyNO: 'หน้าสำรวจ',
    subDistrict: 'ตำบล/แขวง',
    district: 'อำเภอ/เขต',
    province: 'จังหวัด',
    projectAreaRai: '(ไร่)',
    projectAreaNgan: 'เนื้อที่            (งาน)',
    projectAreaSq: '(ตร.วา)',
    foreignersProportion: 'สัดส่วนการถือครองกรรมสิทธิ์ห้องชุดของคนต่างด้าว',
    buildingPlan: 'แผนผังอาคาร/',
    floorPlan: 'แผนผังชั้น/',
    projectApartmentPlan: 'แผนผังโครงการ',
    landOfficeName: 'ข้อมูลสำนักงานที่ดิน',
    landOfficeLocation: 'ตำแหน่งสำนักงานที่ดิน',
    landOfficePhoneNo: 'เบอร์โทรศัพท์ติดต่อ',
    floorPlanPdf:'แผนผังชั้น',
  },
  popContainerFixedRoom:{
    apartmentNo: 'เลขที่ห้องชุด',
    floor: 'ชั้นที่',
    buildingName: 'หมายเลข/ชื่ออาคาร',
    ownershipRatio: 'อัตราส่วนกรรมสิทธิ์ในทรัพย์ส่วนกลาง',
    condominiumProjectName: 'ชื่อโครงการอาคารชุด',
    condominiumRegistrationNo: 'ทะเบียนอาคารชุดเลขที่',
    thaiYear: 'ปี พ.ศ. ที่จดทะเบียน',
    condominiumJuristicPersonName: 'ชื่อนิติบุคคลอาคารชุด',
    condominiumJuristicPersonLocation: 'ที่ตั้งสำนักงานนิติบุคคลอาคารชุด',
    roomSize: 'พื้นที่ห้อง (ตร.ม.)',
    parkingArea: 'พื้นที่จอดรถ (ตร.ม.)',
    balconyArea: 'พื้นที่ระเบียง (ตร.ม.)',
    airConditionerArea: 'พื้นที่แอร์ (ตร.ม.)',
    otherArea: 'พื้นที่อื่นๆ (ตร.ม.)',
    apartmentTotalArea: 'ขนาดพื้นที่รวมห้องชุด (ตร.ม.)',
    linkToTheTreasuryDepartment: 'ลิงค์ตรวจสอบราคาประเมินห้องชุดไปยังกรมธนารักษ์',
    landOfficeName: 'ข้อมูลสำนักงานที่ดิน',
    landOfficeLocation: 'ตำแหน่งสำนักงานที่ดิน',
    landOfficePhoneNo: 'เบอร์โทรศัพท์ติดต่อ',
    apartmentTax: 'ข้อมูลภาษีที่ดินและสิ่งปลูกสร้าง',
    toTreasuryDepartment: 'ตรวจสอบราคาประเมินห้องชุด',
    toBuildingTaxes: 'ตรวจสอบภาษีที่ดินและสิ่งปลูกสร้าง',
  },
  layerManagement:{
    apartmentLayer: 'ชั้นข้อมูลห้องชุด 3 มิติ (LOD2)',
    condominiumLayer: 'ชั้นข้อมูลอาคารชุด 3 มิติ (LOD1, LOD2)',
    buildingLayer: 'ชั้นข้อมูลอาคาร 3 มิติ (LOD1) จากแหล่งข้อมูลอื่นๆ'
  },
  popAreaContainer:{
    title: 'การใช้ประโยชน์พื้นที่',
    roomType: 'ชนิดทรัพย์สิน',
    roomArea: 'พื้นที่รวม (ตร.ม.)'
  },
}