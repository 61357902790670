import { createApp } from "vue";
import {
  Button,
  Layout,
  Dropdown,
  Menu,
  Divider,
  ConfigProvider,
  Select,
  Avatar,
  Tabs,
  Space,
  Icon,
  Input,
  Table,
  Popover,
  Carousel,
  Checkbox,
  Popconfirm,
  DatePicker,
  Card,
  Switch,
  Col,
  Row,
  Slider,
  InputNumber,
  Radio,
  Modal,
  Spin,
  Form,
} from 'ant-design-vue';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from './i18n/index';
//SuperMap组件引用
import components from './components/index';
import URL_CONFIG from './common/js/config';
// import 'default-passive-events';
// import VueAxios from 'vue-axios'
window.URL_CONFIG = URL_CONFIG;

const app = createApp(App);
// app.config.globalProperties.$axios = axios
// axios.defaults.baseURL = '/sqlAPI'
// axios.defaults.headers.post['Content-Type'] = 'application/json';

// app.config.productionTip = false
// app.use(VueAxios, axios);

app
  .use(store)
  .use(router)
  .use(ConfigProvider)
  .use(Button)
  .use(Layout)
  .use(Dropdown)
  .use(Menu)
  .use(Divider)
  .use(i18n)
  .use(Select)
  .use(Avatar)
  .use(Tabs)
  .use(Space)
  .use(Icon)
  .use(Input)
  .use(Table)
  .use(Spin)
  .use(Popover)
  .use(Carousel)
  .use(Checkbox)
  .use(Popconfirm)
  .use(components)
  .use(DatePicker)
  .use(Card)
  .use(Switch)
  .use(Col)
  .use(Row)
  .use(Slider)
  .use(InputNumber)
  .use(Radio)
  .use(Modal)
  .use(Form)
  .mount("#app");

