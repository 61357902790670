import axios from "axios";
import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/user",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../layouts/UserLayout"),
    children: [
      {
        path: '/user',
        redirect: "/user/login",
      },
      {
        path: "/user/login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/User/Login"),
      },
      {
        path: "/user/register",
        name: "register",
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/User/Register"),
      },
    ],
  },
  {
    path: "/webgis3d",
    name: "3d",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../layouts/BasicLayout"),
    children: [
    ]
  },
  {
    path: "/map",
    name: "2d",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../layouts/MapBasicLayout"),
    children: [
    ]
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../views/404.vue"),
  },
  {
    path: "/",
    redirect: "/webgis3d"
  },
  {
    path: "/:catchAll(.*)", // 不识别的path自动匹配404
    redirect: '/404',
  },
];

const router = createRouter({
  mode:'hash',
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  // console.log(to)
  try {
    const res = await axios.get("/iportal/web/mycontent/account.json");
    // console.log(res);
    window.accountInfo = res.data;
  } catch(e) {
    if(e.response) {
      window.location.href = "/iportal/web-ui/login?backUrl="+ `${window.location.origin}/dol`;
      return;
    }
  }
})

export default router;
