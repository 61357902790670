var URL_CONFIG = {
  TDT_IMG : 'https://[subdomain].tianditu.com/img_w/wmts',//天地图影像
  TDT_LABEL : 'https://[subdomain].tianditu.com/cia_w/wmts',//天地图文字注记
  BINGMAP : '//dev.virtualearth.net',//bing map影像
  BING_MAP_KEY: 'ApuX0xxOLB0rZkG-O0DdI2g9fCIu8pF5tMB0vism8ISqkT5CwhUyflOWo_zmUci9',
  SCENE_Condo_Thai: 'http://202.139.198.248:8090/iserver/services/3D-DOL_Model_20230220/rest/realspace/datas/Model_20230219_1%403D%20Model/config',
  DATA_Condo_Thai:'http://202.139.198.248:8090/iserver/services/data-DOL_Model_20230220/rest/data',
  DATASOURCE: '3D Model',
  DATASET: 'Model_20230219',
  LAYERNAME: 'Model_20230219_1@3D Model', //'Model_20221213@3D Model',
  SQLAPI: 'https://condomaps.dol.go.th:8034/api',
  portalService: 'http://localhost:8190/', //'http://202.139.198.248:8190',
  DATASERVICES: [],
  LAYERSERVICES: [],
  LAYERNAMES: [],
  CLICKEVENT: {},
  PUBLICPARAMETER: {},
};



    export default URL_CONFIG;
