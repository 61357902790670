import { createStore } from "vuex";

export default createStore({
  state: {
      isInitViewer: false,
      // 控制界面显隐，0默认隐藏，1显示
      ToolBarShow: false,
      toolBar: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//控制左上角工具栏是否显示对应的功能界面，1代表显示
      addLayer: [1, 0, 0, 0, 0],//添加图层的四个分项的显隐
      sceneAtttribute: [1, 0, 0, 0, 0],
      terrain: [1, 0, 0, 0],
      clip: [1, 0, 0, 0],
      analysis: [1, 0, 0, 0, 0],
      cityPlan: [1, 0, 0, 0, 0],
      onlineEdit: [1, 0, 0, 0],
      specialEffects: [0, 0, 0, 0,0], //特效
      hotSpots: [0, 0], //热点
      compass: true,
      infoManage:true,
      // 图层管理
      S3MLayerManage: null,
      imgLayerManage: null,
      terrainLayerManage: null,
      LayerAttributeToolbar: false, //图层属性显隐
      LayerAttribute: [1, 0, 0, 0],
      selectedLayerName: null, //当前选中编辑图层name
      // 编辑
      isEdit:false,
      isEditZ:false,
      zindex:99,  //动态改变z-index
      toolBaseColor:[true,true,true,true,true,true,true,true,true],
      isInitEcharts:false
    },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
