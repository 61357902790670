<template>
  <a-config-provider :locale="language">
    <router-view />
  </a-config-provider>
</template>

<script>
  import enUS from 'ant-design-vue/es/locale/en_US';
  import thTH from 'ant-design-vue/es/locale/th_TH';
  import { useI18n } from 'vue-i18n';
  import {ref, watch } from 'vue';
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  dayjs.locale('en');

  export default {
    computed:{
      language(){
        if (window.localStorage.getItem("lang")=="en")
          return enUS
        else
          return thTH
      }
    },
    setup() {
      //const { locale } = useI18n();
      //console.log(locale.value);
      //const lang = locale.value;
      
      // watch(locale, val => {
      //   dayjs.locale(val);
      // });
       return {
        dayjs,
        enUS,
        thTH,
      };
    }
  };
</script>

<style>

</style>