import { createI18n } from 'vue-i18n' //引入vue-i18n组件
import localenUS from './enUS';
import localthTH from './thTH';
import antenUS from 'ant-design-vue/es/locale/en_US';
import antthTH from 'ant-design-vue/es/locale/th_TH';

const messages = {
  'en': { ...antenUS,...localenUS, },
  'th': { ...antthTH,...localthTH, }
}
// const language = (navigator.language || 'localenUS').toLocaleLowerCase() // 这是获取浏览器的语言
// window.localStorage.setItem("lang", 'en');

const i18n = createI18n({
  legacy: false,
  locale: window.localStorage.getItem("lang")?window.localStorage.getItem("lang"):'th', 
  fallbackLocale: 'en', // 设置备用语言
  messages, 
  globalInjection: true,
})
window.i18n=i18n
export default i18n