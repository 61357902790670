export default {
  title: "CondoMaps",
  header: {
    feedback: 'Feedback',
    news: 'News',
    submit: 'Submit',
    cancel: 'Cancel',
    userNum: 'Online users:',
    home: 'Home',
    logout: 'Logout',
  },
  leftContainer: {
    condominium: 'Condominium',
    apartment: 'Apartment',
    province: 'Province',
    category: 'Category',
    search: 'Search',
    reset: 'Reset',
    input: 'Input search text',
    select: 'Select a category',
    licInput: 'Condominium License',
    regInput: 'Registration Year',
    loading: 'loading',
    options: {
      titleDeedNumber: 'Title Deed Number',
      condominiumRegistrationNumber: 'Condominium Registration Number',
      condominiumName: 'Condominium Name',
      apartmentRegistrationNumber: 'Apartment Registration Number',
      apartmentName: 'Apartment Name',
      apartmentRoomNumber: 'Apartment Room Number'
    },
    table: {
      province: 'Province',
      district: 'District',
      titleDeedNum: 'Title Deed Number',
      condoRegisNum: 'Condominium Registration Number',
      condomNameTh: 'Condominium Name (Thai)',
      condomNameEn: 'Condominium Name (En)',
      officeName: 'Land Office Name',
      roomNum:'Apartment Room Number',
      thaiYear: 'Registration Year',
      model: '3D Model',
    }
  },
  rightContainer: {
    layerManagment: 'Layers Managment',
    measurement: 'Measurement',
    distance: 'Distance',
    area: 'Area',
    height: 'Height',
    apartmentArea: 'Room Area',
    clear: 'Clear',
    layer: 'Layer',
    baseMap: 'Basemap'
  },
  popContainer:{
    condominiumInfo: 'Condominium Infomation',
    apartmentInfo: 'Apartment Infomation',
  },
  indoorBox: {
    indoor: 'Select Floor',
    offset: 'Offset',
    displayUpFloors: 'Display Upper Floor',
    yes: 'Yes',
    no: 'No'
  },
  popContainerFixed:{
    condominiumName: 'Condominium name',
    projectLocation: 'Project location',
    buildingNo: 'Number of buildings',
    floorsNo: 'Number of floors',
    roomsNo: 'Number of rooms',
    condominiumJuristicPerson: 'Name of condominium juristic person',
    condominiumJuristicPersonOfficeLocation: 'Location of condominium juristic person office',
    condominiumJuristicManagerName: 'Condominium juristic manager name',
    condominiumLocation: 'Location of condominium',
    titleDeedNo: 'Title deed number',
    parcelSurveyNO: 'Parcel Survey NO',
    subDistrict: 'Sub-district',
    district: 'District',
    province: 'Province',
    projectAreaRai: '(Rai)',
    projectAreaNgan: 'Project area (Ngan)',
    projectAreaSq: '(Sq.Wa)',
    foreignersProportion: 'Proportion of foreigners',
    buildingPlan: 'Building plan/',
    floorPlan: 'Floor plan/',
    projectApartmentPlan: 'Project apartment plan',
    landOfficeName: 'Name of land office',
    landOfficeLocation: 'Location (geographic coordinate) of Land office',
    landOfficePhoneNo: 'Phone number of Land office',
    floorPlanPdf:'Floor Plan',
  },
  popContainerFixedRoom:{
    apartmentNo: 'Apartment number',
    floor: 'Floor',
    buildingName: 'Building name',
    ownershipRatio: 'Ownership ratio in common property',
    condominiumProjectName: 'Condominium project name',
    condominiumRegistrationNo: 'Registration number of condominium',
    thaiYear: 'Registration Year',
    condominiumJuristicPersonName: 'Condominium juristic person name',
    condominiumJuristicPersonLocation: 'Location of condominium juristic person',
    roomSize: 'Room size (Sq.m.)',
    parkingArea: 'Parking area (Sq.m.)',
    balconyArea: 'Balcony area (Sq.m.)',
    airConditionerArea: 'Air-conditioner area (Sq.m.)',
    otherArea: 'Other area (Sq.m.)',
    apartmentTotalArea: 'Total area of apartment (Sq.m.)',
    linkToTheTreasuryDepartment: 'Link to check the appraisal price of a condominium unit to the Treasury Department',
    landOfficeName: 'Name of land office',
    landOfficeLocation: 'Location (geographic coordinate) of Land office',
    landOfficePhoneNo: 'Phone number of Land office',
    apartmentTax: 'Apartment tax',
    toTreasuryDepartment: 'Treasury Department',
    toBuildingTaxes: 'Pay Land and Building Taxes',
  },
  layerManagement:{
    apartmentLayer: '3D Apartment layer (LOD2)',
    condominiumLayer: '3D Condominium layer (LOD1, LOD2)',
    buildingLayer: '3D Building layer (LOD1) from other sources'
  },
  popAreaContainer:{
    title: 'Building Utilities Use',
    roomType: 'Room Type',
    roomArea: 'Total area (Sq.m.)'
  },
}